/* Show minimal needed information to get date
 * ex. if it is to_day only show hour and minutes
 * ex. if it is yesterday show day and hour and minutes
 * ex. if it is this year show day and month
 * else show day and month and year
 */
export const formatTime = (
  time: number | string,
  locale?: string,
  options: { keepTime?: boolean; keepSeconds?: boolean; keepDate?: boolean } = {
    keepTime: true,
  }
) => {
  time = new Date(time).getTime();
  locale = locale || navigator.language;
  const now = Date.now();
  const year = new Date(time).getFullYear();
  const nowYear = new Date(now).getFullYear();
  const nowDay = new Date();
  nowDay.setHours(0);
  nowDay.setMinutes(0);
  const oneDayLater = time < nowDay.getTime();
  return new Intl.DateTimeFormat(locale, {
    year: nowYear !== year || options?.keepDate ? "numeric" : undefined,
    month: oneDayLater || options?.keepDate ? "short" : undefined,
    day: oneDayLater || options?.keepDate ? "numeric" : undefined,
    hour: options?.keepTime ? "numeric" : undefined,
    minute: options?.keepTime ? "numeric" : undefined,
    second: options?.keepSeconds ? "numeric" : undefined,
  }).format(new Date(time));
};

export const formatDuration = (duration: number) => {
  duration = Math.floor(duration / 1000 / 60);
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  return `${hours}h ${(minutes + "").padStart(2, "0")}m`;
};

export const toDateISO = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const toDateISOFr = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
};

//Temp fix for date search
export const convertISOStringsToShortFormat = (
  obj: Record<string, any>
): void => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (value instanceof Date) {
      obj[key] = value
        .toLocaleString("fr", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-");
    } else if (typeof value === "object" && value !== null) {
      convertISOStringsToShortFormat(value);
    }
  });
};

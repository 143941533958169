import { Page } from "@atoms/layout/page";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { useRef, useState } from "react";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { Base, Title } from "@atoms/text";
import { SupplierOrderPrepHeader } from "@features/supplier/types";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { Checkbox } from "@atoms/input/input-checkbox";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import Tabs from "@atoms/tabs";
import SupplierOrderPrepHeaderCard from "@components/supplier/order-cards";
import { Button } from "@atoms/button/button";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useRecoilState, useSetRecoilState } from "recoil";
import { SendOrderModal } from "../send-order-modal";
import { InputEdit } from "@atoms/input/input-edit";
import { ButtonConfirm } from "@atoms/button/confirm";
import { PlusCircleIcon, XIcon } from "@heroicons/react/outline";
import toast from "react-hot-toast";
import { SendOrderHeaders } from "@features/supplier/state/store";
import { SendMultiOrderModal } from "../send-order-modal/multi-order-modal";
import { SearchProductsAtom } from "@features/products/state/store";
import { SearchProductModalAtom } from "../search-product-modal";

export const SupplierSendOrderPage = () => {
  const {
    getOrderPreparationHeaders,
    changeSelectedPrepHeader,
    updateOrderPreparationLineGrp,
    deleteOrderPreparationLineGrp,
    getModesExpedition,
    loading,
  } = useSuppliers();
  const [sendOrderModal, setSendOrderModal] = useRecoilState(SendOrderModal);
  const [sendMultiOrderModal, setSendMultiOrderModal] =
    useRecoilState(SendMultiOrderModal);
  const setSearchProductModal = useSetRecoilState(SearchProductModalAtom);

  const setProducts = useSetRecoilState(SearchProductsAtom);

  const { current } = useShopLocations();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<SupplierOrderPrepHeader[]>(
    []
  );

  const [, setOnModifHeader] = useState<SupplierOrderPrepHeader>();
  const [sameFourn, setSameFourn] = useState(false);
  const [modesExpedition, setModesExpedition] = useState<
    { value: string; label: string }[] | null
  >(null);

  const [orderHeaders, setOrderHeaders] = useRecoilState(SendOrderHeaders);

  const [tabIndex, setTabIndex] = usePersistedState<number | string>(
    "user",
    "fourn-envoi-cde-index",
    current && current.items
      ? current!.items.filter(
          (el) => el.modeGest === "L" || el.modeGest === "P"
        )[0].codeTypeProd
      : ""
  );

  const [modeGestion, setModeGestion] = useState(
    current && current.items
      ? current!.items.filter((el) => el.codeTypeProd === tabIndex)[0].modeGest
      : "L"
  );

  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "supplier-send-order-grid-mode",
    {
      activated: false,
    }
  );

  const [searchFieldsValue, setSearchFieldsValue] = usePersistedState<any>(
    "user",
    "product-filters",
    {}
  );

  const codeTypeProdRef = useRef<string | number>(
    current && current.items
      ? current!.items.filter(
          (el) => el.modeGest === "L" || el.modeGest === "P"
        )[0].codeTypeProd
      : ""
  );

  const handleCloseProductSearchModal = () => {
    setSearchProductModal({ open: false, order: undefined });
  };

  const handleUpdateHeader = async (
    orderHeader: SupplierOrderPrepHeader,
    panie: string,
    codeModeExpe: string
  ) => {
    await updateOrderPreparationLineGrp(orderHeader, codeModeExpe, panie);

    const res = await getOrderPreparationHeaders(
      tabIndex.toString(),
      current?.codeLieu
    );
    if (res) setOrderHeaders(res);
    else setOrderHeaders([]);
  };

  useControlledEffect(() => {
    const getModeExp = async () => {
      setModesExpedition([
        { label: "<Aucun>", value: "" },
        ...(await getModesExpedition()).map((el) => {
          return { label: `${el.lib} (${el.code})`, value: el.code };
        }),
      ]);
    };

    getModeExp();
  }, []);

  useControlledEffect(() => {
    if (selectedRows.length > 1)
      setSameFourn(
        selectedRows.every((row) => row.codeFourn === selectedRows[0].codeFourn)
      );
    else setSameFourn(false);
  }, [selectedRows]);

  useControlledEffect(() => {
    const handleEvent = async (event: Event) => {
      setTabIndex((previous) => {
        const newCodeTypeProd = previous !== "" ? previous : tabIndex;
        codeTypeProdRef.current = newCodeTypeProd; // Met à jour la référence
        return previous;
      });
      const res = await getOrderPreparationHeaders(
        codeTypeProdRef.current.toString(),
        current?.codeLieu
      );
      if (res) setOrderHeaders(res);
    };

    window.addEventListener("sendOrderEvent", handleEvent);

    return () => {
      window.removeEventListener("sendOrderEvent", handleEvent);
    };
  }, []);

  useControlledEffect(() => {
    const messageHandler = async (event: MessageEvent) => {
      if (event.data.action === "closeModal") {
        handleCloseProductSearchModal();
        if (event.data.product) {
          window.location.reload();
        }
      }
    };

    window.addEventListener("message", messageHandler);

    // Nettoyage de l'événement lors du démontage du composant
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  const orderColumns: Column<SupplierOrderPrepHeader>[] = [
    {
      title: "Fournisseur",
      id: "codeFourn",
      render: (p) => p.codeFourn,
      orderable: true,
    },
    {
      title: "Raison-sociale",
      id: "raisSocial",
      render: (p) => p.raisSocial,
      orderable: true,
    },
    {
      title: "Panier",
      id: "panie",
      render: (p) => (
        <InputEdit
          inputClassName="min-w-20"
          value={p.panie}
          options={[]}
          onStartEdit={() => setOnModifHeader({ ...p })}
          onStopEdit={() => setOnModifHeader(undefined)}
          onChange={(e) => {
            setOrderHeaders((previous) => {
              const tempTab = [...previous];
              const lineIndex = previous.findIndex((el) => el.find === p.find);
              const tempLine = { ...tempTab[lineIndex] };
              tempLine.panie = e.target.value;
              tempTab[lineIndex] = tempLine;
              return tempTab;
            });
          }}
          onValid={async (newValue) => {
            setOnModifHeader((previous) => {
              handleUpdateHeader(
                { ...p, panie: previous!.panie },
                newValue,
                p.codeModeExpe
              );
              return undefined;
            });
          }}
        />
      ),
      orderable: true,
    },
    {
      title: "Mode exp.",
      id: "codeModeExpe",
      searchIds: ["codeModeExpe", "libModeExpe"],
      render: (p) => (
        <InputEdit
          value={p.codeModeExpe}
          fieldType="select"
          options={modesExpedition ? modesExpedition : []}
          onStartEdit={() => setOnModifHeader({ ...p })}
          onStopEdit={() => setOnModifHeader(undefined)}
          onChange={(e) => {
            setOrderHeaders((previous) => {
              const tempTab = [...previous];
              const lineIndex = previous.findIndex((el) => el.find === p.find);
              const tempLine = { ...tempTab[lineIndex] };
              tempLine.codeModeExpe = e.target.value;
              tempTab[lineIndex] = tempLine;
              return tempTab;
            });
          }}
          onValid={async (newValue) => {
            setOnModifHeader((previous) => {
              handleUpdateHeader(
                { ...p, codeModeExpe: previous!.codeModeExpe },
                p.panie,
                newValue
              );
              return undefined;
            });
          }}
        />
      ),
    },
    {
      title: "Qte",
      id: "qte",
      type: "number",
      render: (p) => p.qte,
      orderable: true,
    },
    {
      title: "Brut",
      id: "mtBase",
      type: "price",
      render: (p) =>
        `${
          modeGestion === "P"
            ? p.mtBase.toFixed(current?.devisNbDecimP)
            : p.mtBase.toFixed(current?.devisNbDecim)
        }
          ${current?.devisSymb || "€"}`,
    },
    {
      title: "net HT",
      id: "mtNetHt",
      type: "price",
      render: (p) =>
        `${
          modeGestion === "P"
            ? p.mtNetHt.toFixed(current?.devisNbDecimP)
            : p.mtNetHt.toFixed(current?.devisNbDecim)
        }
          ${current?.devisSymb || "€"}`,
    },
    {
      title: "Franco",
      type: "number",
      render: (p) => p.francPort,
    },
    {
      title: "Franco atteint",
      id: "francoAtteint",
      type: "boolean",
      render: (p) => (
        <div className="w-full flex justify-center">
          <Checkbox disabled={true} value={p.francoAtteint} />
        </div>
      ),
    },
    {
      title: "Référ",
      id: "ref",
      render: (p) => <Base>{p.ref}</Base>,
    },
    {
      title: "Ligne",
      id: "nbrLigne",
      type: "number",
      render: (p) => p.nbrLigne,
    },
    {
      title: "En envoi",
      id: "enCours",
      render: (p) => (
        <div className="w-full flex justify-center">
          <Checkbox disabled={true} value={p.enCours} />
        </div>
      ),
    },
    {
      title: "Qte cli",
      id: "qteCli",
      type: "number",
      render: (p) => p.qteCli,
    },
    {
      title: "Poids",
      id: "totPoids",
      type: "number",
      render: (p) => p.totPoids,
    },
    {
      title: "",
      id: "deleteColumn",
      noSearch: true,
      render: (p) => (
        <div className="w-full flex justify-center gap-1">
          <ButtonConfirm
            confirmTitle="Supprimer l'envoi de commande"
            confirmButtonTheme="danger"
            confirmMessage="Êtes-vous sûr de vouloir supprimer cette envoi de commande ?"
            theme="danger"
            size="sm"
            onClick={async (e) => {
              e.stopPropagation();
              const res = await deleteOrderPreparationLineGrp(p);
              if (res) toast.success("Commande en préparation supprimée");
              const headers = await getOrderPreparationHeaders(
                tabIndex.toString(),
                current?.codeLieu
              );
              if (headers) setOrderHeaders(headers);
              else setOrderHeaders([]);
            }}
            icon={({ className }) => <XIcon className={className} />}
            data-tooltip="Supprimer la commande en préparation"
          />
        </div>
      ),
    },
  ];

  return (
    <Page
      loading={loading && !sendOrderModal.open && !sendMultiOrderModal.open}
    >
      <>
        <div className="flex justify-between items-center">
          <Title className="w-full">Envoi des commandes</Title>
          <div className="flex w-full justify-end gap-4">
            <Button
              theme="outlined"
              size="sm"
              disabled={selectedRows.length !== 1}
              icon={(p) => <PlusCircleIcon {...p} />}
              onClick={() => {
                changeSelectedPrepHeader(selectedRows[0]);
                if (searchFieldsValue)
                  // Juste pour le linter
                  setSearchFieldsValue({
                    type: selectedRows[0].codeTypeProd,
                    _advanced: true,
                    external_db: true,
                    fournisseur: {
                      label: selectedRows[0].codeFourn,
                      value: selectedRows[0].codeFourn,
                      codeRech: selectedRows[0].codeFourn,
                    },
                  });
                setProducts({
                  pageCount: 0,
                  totalRows: 0,
                  pageNumber: 0,
                  pageSize: 0,
                  items: [],
                });
                setSearchProductModal({ open: true });
              }}
            >
              Ajouter un article
            </Button>
            <Button
              size="sm"
              disabled={selectedRows.length === 0}
              onClick={() => {
                if (selectedRows.length > 1 && !sameFourn) {
                  setSendMultiOrderModal({ open: true, orders: selectedRows });
                } else if (selectedRows.length > 1 && sameFourn) {
                  setSendOrderModal({
                    open: true,
                    codeTypeProd: selectedRows[0].codeTypeProd,
                    codeLieuPourQui: selectedRows[0].codeLieuPourQui,
                    niv: selectedRows[0].niv,
                    orderHeaderList: selectedRows,
                    mode:
                      selectedRows.length > 1 && !sameFourn
                        ? "global"
                        : sameFourn
                        ? "envFourn"
                        : "",
                  });
                } else {
                  setSendOrderModal({
                    open: true,
                    codeTypeProd: selectedRows[0].codeTypeProd,
                    codeLieuPourQui: selectedRows[0].codeLieuPourQui,
                    niv: selectedRows[0].niv,
                    orderHeaderList: selectedRows,
                    mode:
                      selectedRows.length > 1 && !sameFourn
                        ? "global"
                        : sameFourn
                        ? "envFourn"
                        : "",
                  });
                }
              }}
            >
              {selectedRows.length <= 1
                ? "Envoi unitaire"
                : selectedRows.length === orderHeaders.length
                ? "Envoi global"
                : sameFourn
                ? "Envoi par fournisseur"
                : "Envoi par selection multiple"}
            </Button>
          </div>
        </div>

        <div className="flex flex-col grow items-center gap-6">
          <Tabs
            tabs={
              current?.items
                ? current?.items
                    ?.filter((el) => el.modeGest === "L" || el.modeGest === "P")
                    .map((el) => {
                      return { value: el.codeTypeProd, label: el.libTypeProd };
                    })
                : []
            }
            value={tabIndex}
            onChange={async (v) => {
              if (current && current.items) {
                setTabIndex(
                  current.items.find((el) => el.codeTypeProd === v)
                    ?.codeTypeProd || "P"
                );

                setModeGestion(
                  current.items.find((el) => el.codeTypeProd === v)?.modeGest ||
                    "P"
                );

                codeTypeProdRef.current =
                  current.items.find((el) => el.codeTypeProd === v)
                    ?.codeTypeProd || "P";
              }
            }}
          />
        </div>
        {/* <Button
          className="mt-4 md:mt-0"
          authorized={functionsAuthorization[`EnvCde-${tabIndex}`].actif}
          //onClick={() => setSendOrderModal({ open: true })}
        >
          Envoi commande
        </Button> */}
        {modesExpedition && (
          <TableGridSwitch
            key={tabIndex}
            className="w-full overflow-x-scroll"
            name="cdeFournHeader"
            data={orderHeaders}
            onChangeMode={(v) => setGridMode({ activated: v })}
            searchModeEnabled={true}
            gridMode={gridMode.activated}
            showPagination={"totalOnly"}
            localSort={true}
            tableColumns={orderColumns}
            localOrder={true}
            total={orderHeaders.length}
            onClick={(e) => {
              changeSelectedPrepHeader(e);
              const gestionInfo = current?.items?.find(
                (el) => el.codeTypeProd === e.codeTypeProd
              );

              navigate(
                ROUTES.SupplierSendOrderLinePage.replace(
                  /:modeGest/,
                  gestionInfo ? gestionInfo.modeGest : "L"
                )
                  .replace(/:codeTypeProd/, tabIndex.toString())
                  .replace(
                    /:libTypeProd/,
                    gestionInfo ? gestionInfo?.libTypeProd : ""
                  )
                  .replace(/:codeFourn/, e.codeFourn)
              );
            }}
            onRequestData={async () => {
              const res = await getOrderPreparationHeaders(
                tabIndex.toString(),
                current?.codeLieu
              );
              if (res) setOrderHeaders(res);
              else setOrderHeaders([]);
            }}
            renderGrid={(el) => (
              <SupplierOrderPrepHeaderCard
                orderPrep={el}
                modeExpeList={modesExpedition}
              />
            )}
            rowIndex="find"
            onSelect={(e) => setSelectedRows(e)}
          />
        )}
      </>
    </Page>
  );
};

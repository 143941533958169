import { LoadingState } from "@features/utils/store/loading-state-atom";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { ProductsApiClient } from "../api-client/api-client";
import { ProductAtom } from "./store";
import { useGlobalEffect } from "@features/utils/hooks/use-global-effect";
import toast from "react-hot-toast";
import { getRouteProduct } from "../utils";
import { useParams } from "react-router-dom";
import { Book, ConditionAchat, ProductGraphRequest } from "../types";

export const useProduct = (
  ean: string,
  specific?: string,
  productType?: string,
  autoRefresh = true
) => {
  const [product, setProduct] = useRecoilState(ProductAtom(ean));
  const { type } = useParams<{ type: string }>();
  const [loading, setLoading] = useRecoilState(
    LoadingState(["useProduct+" + ean, true])
  );

  const refresh = useCallback(async () => {
    if (ean.trim() !== "")
      if (autoRefresh) {
        setLoading(true);
        try {
          const prodType = type || productType || "";
          let result = await ProductsApiClient.getProduct(
            ean,
            getRouteProduct(prodType),
            specific
          );
          if (result.codeTypeProd === "LIVR") {
            let r = await ProductsApiClient.getProductConditionAchat(
              ean,
              getRouteProduct(prodType)
            );
            (result as Book).listConditionAchat = r.items as ConditionAchat[];
          }
          // const couvPrev = await ProductsApiClient.getProductCouvPrev(ean);
          // result = {
          //   ...result,
          //   delaiStock: couvPrev.delaiStock,
          //   delaiColor: couvPrev.delaiColor,
          // };

          setLoading(false);
          setProduct(result);
        } catch (err) {
          toast.error("Article non trouvé.");
          setLoading(false);
        }
        setLoading(false);
      }
  }, [ean, specific, productType, autoRefresh, setProduct, setLoading, type]);

  useGlobalEffect(
    "useProduct+" + ean + "+" + specific || "",
    async () => {
      await refresh();
    },
    []
  );

  //Ajouter les debounce quand on aura un backend pour ça
  const getFieldSuggestions = async (query: string) => {
    if (query.trim()) {
      return await ProductsApiClient.getFieldSuggestion(query);
    } else {
      return [];
    }
  };

  const getProductGraphVente = async (graphRequest: ProductGraphRequest) => {
    return await ProductsApiClient.getProductGraphVente(graphRequest);
  };
  const referenceProduct = async () => {
    await ProductsApiClient.referenceProduct(ean);
  };

  return {
    product,
    type,
    loading,
    refresh,
    getFieldSuggestions,
    getProductGraphVente,
    referenceProduct,
  };
};

import { Button } from "@atoms/button/button";
import { Form, SearchForm } from "@molecules/form";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useCustomer } from "@features/customer/state/use-customer";
import { Client, CustomerBloc, LoyaltyCard } from "@features/customer/type";
import { areAllFieldsEmpty } from "@features/utils/forms-check";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import toast from "react-hot-toast";
import { Base, InfoSmall, InfoSmallBold } from "@atoms/text";
import { debounce } from "@features/utils/debounce";
import { useTranslation } from "react-i18next";
import { Frame } from "@atoms/layout/frame";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useLoyalty } from "@features/customer/state/use-loyalty";
import { LoyaltyModalModalAtom } from "./loyalty-modal";
import { useSetRecoilState } from "recoil";

const client: Column<Client> = {
  render: (item) => (
    <div className={"h-full flex flex-col lg:gap-1"}>
      <Base
        className="whitespace-nowrap text-ellipsis overflow-hidden"
        data-tooltip
      >
        {[item["nomCli"], item["prnomCli"]].filter((a) => a.trim()).join(" ")}
      </Base>
      {item.numCli && (
        <InfoSmall
          className="whitespace-nowrap text-ellipsis overflow-hidden"
          data-tooltip
        >
          Num. client: {item.numCli}, Canal vente: {item.codeCanalVente}
        </InfoSmall>
      )}
      <InfoSmall
        className="whitespace-nowrap text-ellipsis overflow-hidden"
        data-tooltip
      >
        {[item["addrMail"], item["mobil"]].filter((a) => a.trim()).join(", ")}
      </InfoSmall>

      <InfoSmall
        className="whitespace-nowrap text-ellipsis overflow-hidden"
        data-tooltip
      >
        {[item["addrVille"] || "", item["addrCp"] || ""]
          .filter((a) => a.trim())
          .join(", ")}
      </InfoSmall>
    </div>
  ),
};

export const ClientSelection = (props: {
  client: Client;
  defined: boolean;
  readonly?: boolean;
  createDisabled?: boolean;
  onlyCpt?: boolean;
  onChange: (value: Client) => void;
}) => {
  const [createClient, setCreateClient] = useState(false);
  const [search, setSearch] = useState({ query: "" });
  const [searchResult, setSearchResult] = useState<Client[] | null>(null);
  const { searchCustomer, createCustomer, getCustomerBlocList } = useCustomer();
  const { getLoyaltyCardInfos } = useLoyalty();
  const [loyaltyCard, setLoyaltyCard] = useState<LoyaltyCard>();
  const [customerBlocs, setCustomerBlocs] = useState<CustomerBloc[] | null>(
    null
  );
  const setLoyaltyModal = useSetRecoilState(LoyaltyModalModalAtom);

  const { t } = useTranslation();

  useControlledEffect(() => {
    const getBlocs = async () => {
      setCustomerBlocs([...(await getCustomerBlocList())]);
    };

    const getLoyaltyCard = async () => {
      if (props.client.fidel && props.client.numCarte) {
        const card = await getLoyaltyCardInfos(props.client.numCarte);
        if (card) setLoyaltyCard(card);
        else setLoyaltyCard(undefined);
        return;
      }
      setLoyaltyCard(undefined);
    };

    getLoyaltyCard();
    if (props.client.bloq && !customerBlocs) getBlocs();
  }, [props.client]);

  return (
    <div className="grow flex flex-col ">
      {(props.defined || createClient) && (
        <div className="grow flex justify-start">
          <Button
            theme="secondary"
            className="mb-6"
            onClick={() => {
              props.onChange({
                numCli: null,
                mobil: "",
                addrMail: "",
                nomCli: "",
                prnomCli: "",
              });
              setCreateClient(false);
            }}
            icon={(p) => <ArrowLeftIcon {...p} />}
          >
            {t("client.Selection dun autre client")}
          </Button>
        </div>
      )}
      {!props.defined && !createClient && (
        <div className="mb-4">
          <SearchForm
            onChange={async (t) => {
              debounce(async () =>
                setSearchResult(await searchCustomer(t, props.onlyCpt))
              );
              setSearch(t as any as { query: string });
            }}
            onSearch={async () =>
              setSearchResult(await searchCustomer(search, props.onlyCpt))
            }
            value={search}
            fields={[
              {
                key: "query",
                type: "text",
                autoFocus: true,
                placeholder: "Chercher un client",
              },
            ]}
          />
        </div>
      )}

      {!props.defined &&
        !props.createDisabled &&
        !createClient &&
        !props.readonly && (
          <div>
            <Button
              className="mb-4 w-full"
              theme="outlined"
              size="lg"
              onClick={() => {
                setCreateClient(true);
                setSearchResult(null);
              }}
              icon={(p) => <PlusCircleIcon {...p} />}
            >
              Nouveau client
            </Button>
          </div>
        )}

      {(props.defined || createClient) && (
        <div className="flex flex-col gap-1">
          <Form
            readonly={props.readonly}
            onChange={(v) => {
              props.onChange(v as Client);
            }}
            value={props.client}
            fields={[
              {
                key: "nomCli",
                label: "Nom",
                type: "text",
                alwaysVisible: true,
              },
              {
                key: "prnomCli",
                label: "Prénom",
                type: "text",
                alwaysVisible: true,
              },
              {
                key: "numCli",
                label: "Numéro Client",
                type: "text",
                alwaysVisible: true,
              },
              {
                key: "addrMail",
                label: "Mail",
                type: props.readonly ? "formatted" : "text",
                format: "mail",
                alwaysVisible: true,
              },
              {
                key: "mobil",
                label: "Téléphone",
                type: props.readonly ? "formatted" : "text",
                alwaysVisible: true,
                format: "phone",
              },

              {
                key: "addrCp",
                label: "Code postal",
                type: "text",
                alwaysVisible: true,
              },
              {
                key: "addrVille",
                label: "Ville",
                type: "text",
                alwaysVisible: true,
              },
            ]}
          />
          <Form
            value={props.client}
            readonly={true}
            onChange={() => {}}
            fields={[
              {
                key: "codeCanalVente",
                label: "Canal de vente",
                type: "text",
                alwaysVisible: true,
              },
            ]}
          />
          <Frame className="flex flex-col">
            <Form
              value={props.client}
              onChange={() => {}}
              fields={[
                {
                  key: "fidel",
                  label: "Fidélité",
                  type: "boolean",
                  alwaysVisible: true,
                },
                {
                  key: "numCarte",
                  label: "Carte",
                  type: "modal",
                  alwaysVisible: true,
                  onClick: () => {
                    setLoyaltyModal({
                      open: true,
                      numCli: props.client.numCli || "",
                      cardId: props.client.numCarte,
                    });
                  },
                },
              ]}
            />
            {loyaltyCard && (
              <InfoSmallBold>{loyaltyCard.libelsolde}</InfoSmallBold>
            )}
          </Frame>
          {props.client.bloq && (
            <Frame>
              <Form
                value={props.client}
                onChange={() => {}}
                fields={[
                  {
                    key: "bloq",
                    label: "Bloqué",
                    type: "boolean",
                    alwaysVisible: true,
                  },
                  {
                    key: "codeRaisBloq",
                    label: "Raison",
                    type: "select",
                    alwaysVisible: true,
                    options: customerBlocs
                      ? [
                          ...customerBlocs.map((el) => {
                            return {
                              label: `${el.lib} (${el.code})`,
                              value: el.code,
                            };
                          }),
                        ]
                      : [],
                  },
                ]}
              />
            </Frame>
          )}
        </div>
      )}

      {searchResult && search.query !== "" && (
        <Table
          emptyTabText={search.query === "" ? " " : undefined}
          showPagination={false}
          onClick={(l) => {
            props.onChange(l as Client);
            setCreateClient(true);
            setSearchResult(null);
          }}
          data={searchResult}
          columns={[client]}
        />
      )}

      <div className="grow flex justify-center">
        {createClient && !props.defined && (
          <Button
            disabled={areAllFieldsEmpty(props.client)}
            className="my-6"
            onClick={async () => {
              const res = await createCustomer(props.client);
              if (res.numCli) {
                props.onChange({ ...props.client, numCli: res.numCli });
                toast.success(
                  "Création du nouveau client effectuée avec succés"
                );
              } else {
                toast.error(
                  "Ce compte client existe déjà: " + (res as any).title
                );
                let s = await searchCustomer({
                  query: Object.values(props.client).join(" "),
                });
                if (s.length > 0) {
                  setSearchResult(s);
                }
              }
            }}
            icon={(p) => <ArrowRightIcon {...p} />}
          >
            Créer client
          </Button>
        )}
      </div>
    </div>
  );
};

import { Page } from "@atoms/layout/page";
import { Base, InfoSmallBold, Section, Title } from "@atoms/text";
import { useState } from "react";
import { Button } from "@atoms/button/button";
import { FolderOpenIcon } from "@heroicons/react/outline";
import { FaClockRotateLeft } from "react-icons/fa6";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { Column } from "@molecules/table/table";
import { SupplierOrder } from "@features/supplier/types";
import { toDateISOFr } from "@features/utils/format/dates";
import { Table } from "@molecules/table";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import Tabs from "@atoms/tabs";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { CommonApiClient } from "@features/general/common/api-client/api-client";
import { Input } from "@atoms/input/input-text";
import { Frame } from "@atoms/layout/frame";
import { useRecoilState } from "recoil";
import { SupplierOrderPagePagination } from "@features/supplier/state/store";

export const SupplierOrderPage = () => {
  const {
    loading,
    supplierOrderList,
    changeSupplierOrderList,
    getFournOrders,
    changeSelectedFournCde,
    getModesExpedition,
  } = useSuppliers();
  const [modesExpedition, setModesExpedition] = useState<
    { value: string; label: string }[] | null
  >(null);
  const [history, setHistory] = useState(false);
  const [productFilter, setProductFilter] = useState("");
  const [pagination, setPagination] = useRecoilState(
    SupplierOrderPagePagination
  );

  const navigate = useNavigate();
  const [statusList, setStatusList] = useState<
    {
      code: string;
      libelle: string;
    }[]
  >();

  const { current } = useShopLocations();
  const [tabIndex, setTabIndex] = usePersistedState<number | string>(
    "user",
    "fourn-cde-index",
    current && current.items
      ? current!.items.filter(
          (el) => el.modeGest === "L" || el.modeGest === "P"
        )[0].codeTypeProd
      : ""
  );

  const [modeGestion, setModeGestion] = useState(
    current && current.items
      ? current!.items.filter((el) => el.codeTypeProd === tabIndex)[0].modeGest
      : "L"
  );

  useControlledEffect(() => {
    const getStatus = async () => {
      const res = await CommonApiClient.getSupplierOrderStatusList();
      if (res) setStatusList(res);
    };
    const getModeExp = async () => {
      setModesExpedition([
        { label: "<Aucun>", value: "" },
        ...(await getModesExpedition()).map((el) => {
          return { label: `${el.lib} (${el.code})`, value: el.code };
        }),
      ]);
    };
    getStatus();
    getModeExp();
  }, []);

  //   const [gridMode, setGridMode] = usePersistedState(
  //     "user",
  //     "reception-all-grid-mode",
  //     {
  //       activated: false,
  //     }
  //   );

  //   const [currentOrder, setCurrentOrder] = usePersistedState<any>(
  //     "user",
  //     "reception-all-order",
  //     {
  //       orderBy: 0,
  //       orderDir: "DESC",
  //     }
  //   );

  //   const [numPage, setNumPage] = usePersistedState(
  //     "user",
  //     "reception-all-num-page",
  //     1
  //   );

  //   const navigate = useNavigate();

  const columns: Column<SupplierOrder>[] = [
    {
      id: "codeFourn",
      title: "Fournisseur",
      render: (p) => p.codeFourn,
      orderable: true,
    },
    {
      id: "numCde",
      title: "Num. Cde",
      render: (p) => p.numCde,
      orderable: true,
    },
    {
      id: "panie",
      title: "Panier",
      render: (p) => p.panie,
      orderable: true,
    },
    {
      id: "codeLieuLivr",
      title: "Livré à",
      render: (p) => p.codeLieuLivr,
      orderable: true,
    },
    {
      id: "codeModeExpe",
      title: "Exp",
      render: (p) =>
        modesExpedition ? (
          modesExpedition.map((el) => {
            if (p.codeModeExpe === el.value) return el.label;
            else return null;
          })
        ) : (
          <></>
        ),
    },
    {
      id: "dateCde",
      title: "Date cde",
      render: (p) => (
        <Base className="whitespace-nowrap">
          {toDateISOFr(p.dateCde)}
          {/* {formatTime(p.dateCde as string, "fr-FR", {
            keepTime: new Date(p.dateCde).getSeconds() === 0 ? false : true,
            keepSeconds: false,
            keepDate: true,
          })} */}
        </Base>
      ),
      orderable: true,
      type: "date",
    },
    {
      id: "dateLivrPrev",
      title: "Livr. prev.",
      render: (p) => (
        <Base className="whitespace-nowrap">
          {toDateISOFr(p.dateLivrPrev)}
          {/* {formatTime(p.dateLivrPrev as string, "fr-FR", {
            keepTime:
              new Date(p.dateLivrPrev).getSeconds() === 0 ? false : true,
            keepSeconds: false,
            keepDate: true,
          })} */}
        </Base>
      ),
      orderable: true,
      type: "date",
    },
    {
      title: "Mode Trans.",
      id: "modeTransCde",
      render: (p) => p.modeTransCde,
      orderable: true,
    },
    {
      id: "trans",
      title: "Trans.",
      render: (p) => p.trans,
    },
    {
      id: "statuCde",
      title: "Statut",
      render: (p) =>
        statusList ? (
          statusList.map((el) => {
            if (p.statuCde === el.code) return `${el.libelle} (${el.code})`;
            else return null;
          })
        ) : (
          <></>
        ),
    },
    {
      id: "dateDernStatu",
      title: "Date Statut",
      type: "date",
      render: (p) => toDateISOFr(p.dateDernStatu),
      orderable: true,
    },
    {
      id: "qte",
      title: "Qté",
      searchIds: ["nbArticle"],
      type: "number",
      render: (p) => p.nbArticle,
      orderable: true,
    },
    {
      id: "nbRef",
      title: "Nbr ref.",
      searchIds: ["ref"],
      type: "number",
      render: (p) => p.ref,
      orderable: true,
    },
    {
      id: "nbLigne",
      title: "Nbr lignes",
      type: "number",
      render: (p) => p.nbrLigne,
      orderable: true,
    },

    {
      id: "refer",
      title: "Référence",
      render: (p) => p.refer,
    },
    {
      id: "edi",
      title: "N° lot EDI",
      render: (p) => p.edi,
    },
    {
      id: "numCdeEdi",
      title: "Num. Cde EDI",
      render: (p) => p.numCdeEdi,
      orderable: true,
    },
    {
      id: "qteRecu",
      title: "Qte reçue",
      type: "number",
      render: (p) => p.qteRecu,
      orderable: true,
    },
    {
      id: "nbrCdeCli",
      title: "Cde clients",
      type: "number",
      render: (p) => p.nbrCdeCli,
      orderable: true,
    },
    {
      id: "mtBrut",
      title: "Mt. brut",
      type: "price",
      render: (p) =>
        p.mtBase ? (
          `${
            p.mtBase && modeGestion === "P"
              ? p.mtBase.toFixed(current?.devisNbDecimP)
              : p.mtBase.toFixed(current?.devisNbDecim)
          }
            ${current?.devisSymb || "€"}`
        ) : (
          <></>
        ),
      orderable: true,
    },
    {
      id: "mtNet",
      title: "Mt. net",
      type: "price",
      render: (p) =>
        p.mtNet ? (
          `${
            p.mtBase && modeGestion === "P"
              ? p.mtNet.toFixed(current?.devisNbDecimP)
              : p.mtNet.toFixed(current?.devisNbDecim)
          }
            ${current?.devisSymb || "€"}`
        ) : (
          <></>
        ),
      orderable: true,
    },
  ];

  return (
    <Page loading={loading}>
      <div className="w-full flex justify-between">
        <Title>Commandes fournisseurs</Title>
        <Button
          disabled={loading}
          theme={history ? "outlined" : "primary"}
          onClick={() => {
            setHistory(!history);
          }}
          icon={({ className }) => {
            return history ? (
              <FolderOpenIcon className={className} />
            ) : (
              <FaClockRotateLeft className={className} />
            );
          }}
          data-tooltip={
            history
              ? "Voir les commandes fournisseurs en cours"
              : "Voir l'historique de commandes"
          }
        >
          {history ? "En cours" : "Historique"}
        </Button>
      </div>

      <div className="grow overflow-hidden">
        <Section>
          {history
            ? "Historique des commandes"
            : "Liste des commandes en cours"}
        </Section>

        <div className="flex flex-col grow items-center gap-6">
          <Tabs
            tabs={
              current?.items
                ? current?.items
                    ?.filter((el) => el.modeGest === "L" || el.modeGest === "P")
                    .map((el) => {
                      return { value: el.codeTypeProd, label: el.libTypeProd };
                    })
                : []
            }
            value={tabIndex}
            onChange={async (v) => {
              setPagination({
                total: 0,
                page: 1,
                perPage: 100,
                order: "DESC",
              });
              if (current && current.items) {
                setTabIndex(
                  current.items.find((el) => el.codeTypeProd === v)
                    ?.codeTypeProd || "P"
                );
              }
              if (current && current.items)
                setModeGestion(
                  current.items.find((el) => el.codeTypeProd === v)?.modeGest ||
                    "P"
                );
            }}
          />
        </div>
        <Frame className="h-full flex max-w-xs justify-start items-center">
          <InfoSmallBold className="w-48 h-full">
            Filtrer par produit:
          </InfoSmallBold>
          <Input
            size="sm"
            className="self-start"
            placeholder="ex: 9782916835884"
            onChange={(e) => {
              if (
                e.target.value.trim() !== "" &&
                e.target.value.trim().length === 13
              )
                setProductFilter(e.target.value);
              else setProductFilter("");
            }}
          />
        </Frame>
        {statusList && modesExpedition && (
          <Table
            key={`${tabIndex}-${history}-${productFilter}`}
            className="w-full overflow-x-scroll"
            headerPagination={true}
            data={supplierOrderList.items ? supplierOrderList.items : []}
            searchModeEnabled={true}
            codePres="supplier"
            //onChangeMode={(v) => setGridMode({ activated: v })}
            showPagination={"full"}
            cellClassName={() => "rounded-lg"}
            initialPagination={pagination}
            onClick={async (cde) => {
              changeSelectedFournCde(cde);
              navigate(
                ROUTES.SupplierOrderLinePage.replace(/:modeGest/, modeGestion)
                  .replace(/:codeTypeProd/, tabIndex.toString())
                  .replace(/:codeFourn/, cde.codeFourn)
                  .replace(/:numCde/, cde.numCde)
              );
            }}
            exportDefaultHeader={[]}
            columns={columns}
            total={
              supplierOrderList.pagination
                ? supplierOrderList.pagination.totalRows
                : 0
            }
            orderables={["Date", "Fournisseur"]}
            onRequestData={async ({
              page,
              perPage,
              order,
              orderBy,
              orderById,
              filters,
            }) => {
              const res = await getFournOrders(
                { ...filters },
                {
                  codeTypeProd: tabIndex.toString(),
                  clot: history,
                  productID: productFilter,
                },
                {
                  orderBy: orderById ? orderById : "dateCde",
                  orderDir: order,
                  pageNumber: page,
                  pageSize: perPage,
                }
              );
              setPagination({
                total: res?.totalRows || 0,
                page: page,
                perPage: perPage,
                orderBy: orderBy,
                orderById: orderById,
                order: order,
                filters: filters,
              });
              changeSupplierOrderList(res.items, {
                pageNumber: res.pageNumber,
                pageSize: res.pageSize,
                pageCount: res.rowsReturned,
                totalRows: res.totalRows,
                rowsReturned: res.rowsReturned,
              });
            }}
            onChangeFilters={async (filters) => {
              const res = await getFournOrders(
                { ...filters },
                {
                  codeTypeProd: tabIndex.toString(),
                  clot: history,
                  productID: productFilter,
                },
                undefined
              );
              setPagination({ ...pagination, filters: filters });
              changeSupplierOrderList(res.items, {
                pageNumber: res.pageNumber,
                pageSize: res.pageSize,
                pageCount: res.rowsReturned,
                totalRows: res.totalRows,
                rowsReturned: res.rowsReturned,
              });
            }}
            gridClassName={
              "grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
            }
          />
        )}
      </div>
    </Page>
  );
};

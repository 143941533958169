import { Page } from "@atoms/layout/page";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { Base, BaseBold, Info, Subtitle, Title } from "@atoms/text";
import { ShippingMode, SupplierOrderLine } from "@features/supplier/types";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { BookRender } from "@atoms/book";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { Button } from "@atoms/button/button";
import { useSetRecoilState } from "recoil";
import { PdfModal } from "@molecules/pdf-viewer/pdf-viewer-modal";
import { ArrowNarrowLeftIcon, PrinterIcon } from "@heroicons/react/outline";
import { SupplierOrderProductCard } from "@components/supplier/order-cards";
import { Frame } from "@atoms/layout/frame";
import { Form } from "@molecules/form";
import { SearchFormFieldType } from "@molecules/form/types";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";

export const SupplierOrderLinePage = () => {
  const [orderLines, setOrderLines] = useState<SupplierOrderLine[]>([]);
  const { modeGest, codeTypeProd, codeFourn, numCde } = useParams<{
    modeGest: string;
    codeTypeProd: string;
    codeFourn: string;
    numCde: string;
  }>();
  const setPdfModal = useSetRecoilState(PdfModal);
  const {
    loading,
    selectedFournCde,
    getFournOrderLines,
    getModesExpedition,
    getSupplierDocument,
  } = useSuppliers();
  const { current } = useShopLocations();
  const [modesExpedition, setModesExpedition] = useState<ShippingMode[] | null>(
    null
  );

  const navigate = useNavigate();

  console.log(modeGest);

  useControlledEffect(() => {
    const getModeExp = async () => {
      setModesExpedition([...(await getModesExpedition())]);
    };

    getModeExp();
  }, []);

  useControlledEffect(() => {
    const timer = setTimeout(() => {
      document.title = `Inférence - Détail cde ${numCde} - ${codeFourn} `;
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const orderHeaderFormFields: SearchFormFieldType[] = [
    {
      key: "numCde",
      label: "Numéro commande",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "codeFourn",
      label: "Fournisseur",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "codeModeExpe",
      label: "Mode expédition",
      type: "select",
      alwaysVisible: true,
      options: modesExpedition
        ? [
            ...modesExpedition.map((el) => {
              return { label: `${el.lib} (${el.code})`, value: el.code };
            }),
          ]
        : [],
    },
    {
      key: "panie",
      label: "Panier",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "dateCde",
      label: "Date commande",
      type: "date",
      alwaysVisible: true,
    },
    {
      key: "dateCreat",
      label: "Création",
      type: "date",
      alwaysVisible: true,
    },
    {
      key: "dateMaj",
      label: "Modification",
      type: "date",
      alwaysVisible: true,
    },
    {
      key: "dateLivrPrev",
      label: "Livraison prévu",
      type: "date",
      alwaysVisible: true,
    },
    {
      key: "nbArticle",
      label: "Nombre d'article",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "mtBase",
      label: "Prix total TTC",
      type: "formatted",
      format: "price",
      alwaysVisible: true,
    },
    {
      key: "mtNet",
      label: "Prix total net",
      type: "formatted",
      format: "price",
      alwaysVisible: true,
    },
  ];

  // --> Liste des colonnes à compléter
  const orderColumns: Column<SupplierOrderLine>[] = [
    {
      title: "Article",
      id: "article",
      searchIds: ["ean13", "desig"],
      render: (p) => (
        <div
          className="flex flex-row items-center space-x-4 cursor-pointer"
          onClick={() =>
            navigate(
              ROUTES.Product.replace(/:ean/, p.ean13).replace(
                /:type/,
                p.tooltip.modeGest
              )
            )
          }
        >
          <div className="w-10 print:hidden">
            <BookRender src={p.imageURL} productType={p.tooltip.modeGest} />
          </div>
          <div className="flex flex-col">
            <Base
              className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
              data-tooltip={p.desig}
            >
              {p.desig}
            </Base>
            <Info>{p.ean13}</Info>
          </div>
        </div>
      ),
    },

    {
      title: "PU TTC",
      id: "prixBase",
      type: "price",
      render: (p) =>
        `${
          modeGest === "P"
            ? p.prixBase.toFixed(current?.devisNbDecimP)
            : p.prixBase.toFixed(current?.devisNbDecim)
        }
          ${current?.devisSymb || "€"}`,
    },

    {
      title: "Opé.",
      id: "codeOpe",
      render: (p) => p.codeOpe,
    },
    {
      title: "Quantité",
      id: "qte",
      type: "number",
      render: (p) => p.qte,
    },
    {
      title: "Quantité reçue",
      id: "qteRecu",
      type: "number",
      render: (p) => p.qteRecu,
    },
    {
      title: "Taux",
      id: "codeTypeTaux",
      render: (p) => p.codeTypeTaux,
    },
    {
      title: "Taux net",
      id: "tauxNet",
      type: "number",
      render: (p) => p.tauxNet + "%",
    },
    {
      title: "Taux net net",
      id: "tauxNetNet",
      type: "number",
      render: (p) => p.tauxNetNet + "%",
    },
    {
      title: "Net HT",
      id: "prixNetHt",
      type: "price",
      render: (p) =>
        `${
          modeGest === "P"
            ? p.prixNetHt.toFixed(current?.devisNbDecimP)
            : p.prixNetHt.toFixed(current?.devisNbDecim)
        }
          ${current?.devisSymb || "€"}`,
    },
    {
      title: "Motif",
      id: "codeMotifManq",
      render: (p) => p.codeMotifManq,
    },
    // {
    //   title: "Nbr cde cli",
    //   id: "",
    //   type: "number",
    //   render: (p) => "",
    // },
  ];

  return (
    <Page loading={loading}>
      <div className="grow flex flex-col gap-8">
        <div className="w-full flex justify-between items-center">
          <Title>Commande fournisseur</Title>
          <div className="flex gap-4">
            <Button
              theme="danger-outlined"
              onClick={() => {
                navigate(ROUTES.SupplierOrderPage);
              }}
              icon={(p) => <ArrowNarrowLeftIcon {...p} />}
            >
              Retour
            </Button>
            <Button
              theme="secondary"
              onClick={async () => {
                const res = await getSupplierDocument(
                  numCde || selectedFournCde.numCde,
                  "cde",
                  modeGest || "L",
                  codeTypeProd || selectedFournCde.numCde,
                  orderLines[0].codeLieuPourQui,
                  current?.centr || false
                );
                if (res) {
                  setPdfModal({
                    open: true,
                    pdfProps: {
                      blobFile: res,
                      iframeSize: {
                        width: "80%",
                        height: "90%",
                      },
                      iFrameClassname:
                        "w-full h-full flex justify-center items-center",
                    },
                  });
                }
              }}
              icon={(p) => <PrinterIcon {...p} />}
            >
              Imprimer bon de commande
            </Button>
          </div>
        </div>
        {modesExpedition && (
          <Frame className="flex flex-col gap-4">
            <BaseBold>Information générales </BaseBold>
            <Form
              readonly={true}
              value={selectedFournCde}
              onChange={() => {}}
              fields={orderHeaderFormFields}
            />
          </Frame>
        )}
        <Frame className="w-full flex flex-col print:hidden">
          <BaseBold>Commentaire:</BaseBold>
          <Subtitle>
            {" "}
            {selectedFournCde.comm && selectedFournCde.comm.length > 0
              ? selectedFournCde.comm
              : "-"}
          </Subtitle>
        </Frame>
        <TableGridSwitch
          className="w-full overflow-x-scroll"
          name="cdeFournLigne"
          data={orderLines}
          //onChangeMode={(v) => setGridMode({ activated: v })}
          //gridMode={gridMode.activated}
          searchModeEnabled={true}
          tableColumns={orderColumns}
          onRequestData={async () => {
            const res = await getFournOrderLines(
              numCde || selectedFournCde.numCde
            );
            if (res) setOrderLines(res);
            else setOrderLines([]);
          }}
          renderGrid={(p) => (
            <SupplierOrderProductCard product={p} modeGest={modeGest || "P"} />
          )}
          gridMode={false}
        />
      </div>
    </Page>
  );
};
